body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.classesInfo{
  text-align: center;
}

/*gallery page*/

.gallery{
  margin: 100px;
}

.gallery-form{
  margin: 30px auto 10px;
  text-align: center;
}

.gallery-form-label input{
  height: 0;
  width: 0;
  opacity: 0;
}

.gallery-form-label{
  display: block;
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 50%;
  margin: 10px auto;
  line-height: 30px;
  color: white;
  font-weight: bold;
  font-size: 24px;
}
.gallery-form-label:hover{
  background: white;
  color: white;
}

.gallery-form-label{
  padding-bottom: 38px
}

.output{
  height: 60px;
  font-size: 0.8rem;
}
.error{
  color: #F13A11;;
}

/*progress bar styles*/

.progressBar{
  height: 5px;
  background: #F13A11;
  margin-top: 20px;
}

/*image grid styles*/

.img-grid {
  max-width: 95rem !important;
  margin: 20px auto;
  display: grid;
  grid-gap: 40px;
}

.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  position: relative;
  opacity: 0.8;
}

.img-wrap:hover{
  cursor: pointer;
}

.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

/*modal styles*/
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 80px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}

#langChange:hover{
  color: #F13A11;
}

#logoImg{
  max-width: 40px;
  width: 100%;
}

.logoContainer{
  margin-right: 10px;
}

#about-img{
  margin-top: 45px;
}